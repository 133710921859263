<template>
  <article>
    <div class="mypage_wrap">
      <div class="date_wrap">
        <div class="month_picker_wrap">
          <DatePickr
            @setStartDate="setStartDate"
            @setEndDate="setEndDate"
            :startDate="startDate"
            :endDate="endDate"
          ></DatePickr>
        </div>
      </div>
      <div class="mypage_log">
        <div class="table_container">
          <table class="log_list">
            <thead>
              <tr>
                <th v-for="(item, idx) in logData.tr" :key="idx">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="list.length != 0">
                <tr v-for="item in list" :key="item.celebIdx">
                  <td
                    v-for="(data, idx) in logData.td"
                    :key="idx"
                    :class="item[data].indexOf('-', 0) == 0 ? 'fc_red' : ''"
                  >
                    {{ delMilliSec(item[data]) }}
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr style="height: 400px; border-bottom: none">
                  <td class="txt_p_c" :colspan="logData.tr.length">
                    내역이 없습니다.
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!-- 페이지네이션-->
        <pagination
          v-if="list.length != 0"
          @goPage="goPage"
          :pageNum="pageInfo.page"
          :pageSize="pageInfo.rowCnt"
          :totalPageCount="pageInfo.totalPageCnt"
        />
      </div>
    </div>
  </article>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  name: "log",
  emits: ["goPage", "setStartDate", "setEndDate"],
  props: ["list", "logData", "pageInfo", "startDate", "endDate"],
  components: { Pagination },
  data() {
    return {};
  },
  methods: {
    setStartDate(date) {
      this.$emit("setStartDate", date);
    },
    setEndDate(date) {
      this.$emit("setEndDate", date);
    },
    goPage(page) {
      this.$emit("goPage", page);
    },
  },
};
</script>
