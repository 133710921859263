<template>
  <div class="pagination-container">
    <ul class="pagination-wrap">
      <li @click="goPage(1)" class="arrow" v-if="pageNum !== 1">
        <button type="button" class="paging">
          <i class="fas fa-angle-double-left"></i>
        </button>
      </li>
      <li @click="goPage(pageNum - 1)" class="arrow" v-if="pageNum !== 1">
        <button type="button " class="paging">
          <i class="fas fa-angle-left"></i>
        </button>
      </li>
      <li v-else>
        <button type="button">
          <i class="fas fa-angle-left"></i>
        </button>
      </li>
      <template v-for="i in navArray" :key="`nav${i}`">
        <li @click="goPage(i)">
          <button
            :class="{ active: pageNum === i }"
            type="button"
            class="paging"
          >
            {{ i < 10 ? "0" + i : i }}
          </button>
        </li>
      </template>
      <li
        @click="goPage(pageNum + 1)"
        class="arrow"
        v-if="!(navMax <= pageNum)"
      >
        <button type="button" class="paging">
          <i class="fas fa-angle-right"></i>
        </button>
      </li>
      <li v-else>
        <button type="button"><i class="fas fa-angle-right"></i></button>
      </li>

      <li @click="goPage(navMax)" class="arrow" v-if="!(navMax <= pageNum)">
        <button type="button" class="paging">
          <i class="fas fa-angle-double-right"></i>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "pagination",
  emits: ["goPage"],
  props: {
    pageNum: {
      type: Number,
      default: 1,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 20,
      required: true,
    },
    totalCount: {
      //list Count
      type: Number,
      default: 0,
      required: false,
    },
    totalPageCount: {
      //page Count
      type: Number,
      default: 0,
      required: true,
    },
    navSize: {
      // 한 페이지 당 출력 개수
      type: Number,
      default: 5,
    },
  },
  computed: {
    navArray() {
      return new Array(Math.max(this.navMin, this.navEnd - this.navStart + 1))
        .fill(this.navStart)
        .map((n, i) => n + i);
    },
    navStart() {
      return Math.max(
        this.pageNum -
          (this.navSize -
            1 -
            Math.min(Math.floor(this.navSize / 2), this.navMax - this.pageNum)),
        this.navMin
      );
    },
    navEnd() {
      return Math.min(
        this.pageNum +
          Math.max(Math.floor(this.navSize / 2), this.navSize - this.pageNum),
        this.navMax
      );
    },
    navMin() {
      return 1;
    },
    navMax() {
      if (this.totalPageCount && this.totalCount) {
        return this.totalPageCount;
      } else if (this.totalPageCount && !this.totalCount) {
        return this.totalPageCount;
      } else if (!this.totalPageCount && this.totalCount) {
        return Math.floor((this.totalCount - 1) / this.pageSize) + 1;
      }
    },
  },
  methods: {
    goPage(no) {
      this.$emit("goPage", no);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  margin: 20px;
}
.pagination-wrap {
  display: flex;
  width: fit-content;
  margin: 0 auto;
  overflow: hidden;
  gap: 3px;
  background: #fff;
  padding: 10px;
  align-items: center;
  /* box-shadow: 0px 0px 10px #ebe7e7; */
}
.pagination-wrap > li {
  display: flex;
  align-items: center;
}
.pagination-wrap > li:nth-child(2):after,
.pagination-wrap > li:not(.arrow):after {
  background: #e8e8f4;
  width: 2px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
  content: "";
  display: inline-block;
}
.pagination-wrap > li:last-child:after {
  display: none;
}
.pagination-wrap button {
  padding: 12px 0;
  min-width: 45px;
  text-align: center;
  background: #fff;
  color: #dfe0e6;
  font-size: 18px;
  user-select: none;
  font-weight: 700;
}

.pagination-wrap .paging {
  cursor: pointer;
}
.pagination-wrap button > i {
  color: #dfe0e6;
}
.pagination-wrap .paging:hover > i {
  color: #484848;
}
.pagination-wrap li.arrow:first-child button,
.pagination-wrap li.arrow button {
  color: #484848;
}
.pagination-wrap button.active {
  color: #484848;
  font-weight: 700;
}

.pagination-wrap button:hover:not(.active) {
  color: #484848;
}
</style>
